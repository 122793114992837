import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as stylesAbout from "../components/about.module.css"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  return (
    <Layout>
      <Seo title="About" />
      <section className={stylesAbout.about_main_block}>
        <div className="wrapper">
          <h1 className={stylesAbout.about_main_title}>About</h1>
        </div>
        <StaticImage src="../images/aboutpage_image.jpg" alt="3Dview team" className={stylesAbout.about_main_block_img} />
        <StaticImage src="../images/aboutpage_image_mobile.jpg" alt="3Dview team" className={stylesAbout.about_main_block_img_mobile} />
      </section>
      <section className={stylesAbout.about_descr}>
        <div className="wrapper">
          <p className={stylesAbout.about_descr_main_p}>We&nbsp;are 3Dview. We&nbsp;are focused on&nbsp;creating high-quality visual content to&nbsp;solve the marketing tasks of&nbsp;our clients.</p>
          <div className={stylesAbout.about_descr_flex}>
            <StaticImage src="../images/aboutpage_img.jpg" alt="3Dview team" className={stylesAbout.about_descr_img} />
            <p className={stylesAbout.about_descr_p}>3Dview is&nbsp;a&nbsp;3D&nbsp;interior and architectural visualization studio. The company was founded in&nbsp;2015. Offices are located in&nbsp;New York and Minsk.</p>
          </div>
        </div>
      </section>
      <section className={stylesAbout.about_owners}>
        <div className="wrapper">
          <h2 className={stylesAbout.about_owners_title}>Owners</h2>
          <div className={stylesAbout.about_owners_grid}>
            <div>
              <StaticImage src="../images/aboutpage_owners1.jpg" alt="3Dview owner" className={stylesAbout.about_owners_img} />
              <p className={stylesAbout.about_owners_p}>CEO, head of&nbsp;the studio, is&nbsp;responsible for strategic management and work with clients. Education: Graduated from BNTU, majoring in&nbsp;engineer</p>
            </div>
            <div>
              <StaticImage src="../images/aboutpage_owners1.jpg" alt="3Dview owner" className={stylesAbout.about_owners_img} />
              <p className={stylesAbout.about_owners_p}>Project Manager, responsible for the project work of&nbsp;the studio and the team. Education: Graduated from BNTU, majoring in&nbsp;personnel management</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default About