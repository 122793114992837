import React, { useEffect } from 'react'
//import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from "../images/logo.svg"

const Header = () => {
  const [navOpen , setNavOpen ]= React.useState(false);
  useEffect(()=>{
    if(navOpen){
      document.body.style.overflow="hidden";
      document.body.style.height="100vh";
    } else{
      document.body.style.height="auto";
      document.body.style.overflow="";
    }
  }, [navOpen]);
  return (
  <header className={( navOpen?"header_open":"")}>
    <div className="wrapper">
      <div className="header_flex">
        <Link to="/" className="header_logo">
          <img src={logo} alt="3dview logo" className="logo_img" />
        </Link>
        <ul className="header_items">
          <li>
            <Link to="/gallery" className="header_item_link" activeClassName="header_item_link_active">Gallery</Link>
          </li>
          <li>
            <Link to="/about" className="header_item_link" activeClassName="header_item_link_active">About</Link>
          </li>
        </ul>
        <div onClick={()=>  { 
          setNavOpen(!navOpen)}} className="header_burger">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="header_mobile">
        <ul className="header_mobile_items">
          <li>
            <Link to="/" className="header_mobile_link">Home</Link>
          </li>
          <li>
            <Link to="/gallery" className="header_mobile_link">Gallery</Link>
          </li>
          <li>
            <Link to="/about" className="header_mobile_link">About</Link>
          </li>
        </ul>
      </div>
    </div>
  </header>
  )
  //const [navOpen , setNavOpen ]= React.useState(false);
  //React.useEffect(()=>{
  //  if(navOpen){
  //    document.body.style.overflow="hidden";
  //    document.body.style.height="100vh";
  //  } else{
  //    document.body.style.height="auto";
  //    document.body.style.overflow=""
  //  }
  //}, [navOpen])

  //return (
  
  //)
}
  
  


//Header.propTypes = {
//  siteTitle: PropTypes.string,
//}

//Header.defaultProps = {
//  siteTitle: ``,
//}

export default Header
