import React, { useEffect, useState } from 'react';

export const IndexAnimatedTitleLine =()=>{

const [state , setState] = useState(false);
    useEffect(()=>{
        if(typeof window != undefined){
            setTimeout(()=>{
                setState(true);
            },50);
        }
    }, []);

return (
    <div className={"index_main_block_line" + (state? " active": "")}></div>
    )
}