// extracted by mini-css-extract-plugin
export var gallery_button = "gallery-module--gallery_button--d8989";
export var gallery_button_p = "gallery-module--gallery_button_p--fbdac";
export var gallery_grid = "gallery-module--gallery_grid--a409d";
export var gallery_grid_block = "gallery-module--gallery_grid_block--f0306";
export var gallery_grid_column = "gallery-module--gallery_grid_column--974fd";
export var gallery_grid_img = "gallery-module--gallery_grid_img--61571";
export var gallery_grid_link = "gallery-module--gallery_grid_link--f139f";
export var gallery_grid_text = "gallery-module--gallery_grid_text--deb5c";
export var stills_gallery_button = "gallery-module--stills_gallery_button--9a87c";
export var stills_images = "gallery-module--stills_images--23b2b";
export var stills_img = "gallery-module--stills_img--7d2b9";
export var stills_p = "gallery-module--stills_p--a426c";
export var stills_text = "gallery-module--stills_text--b1425";
export var stills_title = "gallery-module--stills_title--7db40";
export var wrapper_stills = "gallery-module--wrapper_stills--ad97d";