import * as React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <div className="wrapper">
        <div className="footer_grid">
          <div className="footer_right">
            <a href="mailto:3dview@gmail.com" className="footer_right_item footer_link">3dview@gmail.com</a>
            <a href="tel:+375296748390" className="footer_right_item footer_link">+375296748390</a>
            <p className="footer_right_item">Pobediteley Avenue, 131</p>
            <p className="footer_right_item">Minsk, Belarus</p>
          </div>
          <p className="footer_copyright">2008&nbsp;&mdash; {new Date().getFullYear()}&nbsp;3Dview</p>
          <div className="footer_social">
            <Link to="/" className="footer_social_item footer_link" target="_blank">Instagram</Link>
            <Link to="/" className="footer_social_item footer_link" target="_blank">Telegram</Link>
            <Link to="/" className="footer_social_item footer_link" target="_blank">Behance</Link>
            <Link to="/" className="footer_social_item footer_link" target="_blank">Pinterest</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
