import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as stylesIndex from "../components/index.module.css"
import * as stylesGallery from "../components/gallery.module.css"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Masonry from 'react-masonry-css'
import { IndexAnimatedTitleLine } from '../subitems/animationItems/indexAnimatedTitleLine'
import { IndexAnimatedTitle } from '../subitems/animationItems/indexAnimatedTitle'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <section className={stylesIndex.index_main_block}>
        <div className="wrapper">
          <div className={stylesIndex.index_main_textblock}>
            <IndexAnimatedTitle />
            <IndexAnimatedTitleLine />
          </div>
        </div>
        <StaticImage src="../images/indexpage_image.jpg" alt="3Dview project" className={stylesIndex.index_main_block_img} />
        <StaticImage src="../images/indexpage_image_mobile.jpg" alt="3Dview project" className={stylesIndex.index_main_block_img_mobile} />
      </section>
      <section className={stylesIndex.index_directions}>
        <div className="wrapper">
          <h2 className={stylesIndex.index_directions_title}>Main directions:</h2>
          <ul className={stylesIndex.index_directions_items}>
            <li className={stylesIndex.index_directions_item}>Interior design for real estate and product marketing</li>
            <li className={stylesIndex.index_directions_item}>3D&nbsp;tours for interiors and exteriors</li>
            <li className={stylesIndex.index_directions_item}>High-quality 3D&nbsp;visualization of&nbsp;the exterior, interior and product</li>
          </ul>
        </div>
      </section>
      <section className={stylesIndex.index_gallery_grid}>
        <div className="wrapper">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={stylesGallery.gallery_grid}
            columnClassName={stylesGallery.gallery_grid_column}
          >
            <Link to="/gallery/stills-3" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-3/00-gavan.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-2" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-2/00-kz-office.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-1" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-1/00-dom-v-litve.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-6" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-6/00-bl-office.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-5" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-5/00-fasad-doma.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-4" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-4/00-palazzo.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-11" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-11/00-kz-office.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <></>
            <Link to="/gallery/stills-7" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../images/projects/stills-7/00-parus.jpg" alt="3Dview project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
          </Masonry>
        </div>
      </section>
      <section className={stylesIndex.index_gallery_button}>
        <div className="wrapper">
          <Link to="/gallery" className={stylesGallery.gallery_button}><p className={stylesGallery.gallery_button_p}>See all works</p></Link>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

const breakpointColumnsObj = {
  default: 3,
  768: 1
};