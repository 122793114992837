import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import * as stylesGallery from "../../components/gallery.module.css"
import { StaticImage } from "gatsby-plugin-image"
import Masonry from 'react-masonry-css'


const GalleryPage = () => {
  return (
    <Layout>
      <Seo title="GalleryPage" />
      <section className={stylesGallery.gallery_grid_block}>
        <div className="wrapper">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={stylesGallery.gallery_grid}
            columnClassName={stylesGallery.gallery_grid_column}
          >
            <Link to="/gallery/stills-1" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-1/00-dom-v-litve.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-2" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-2/00-kz-office.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-3" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-3/00-gavan.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-4" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-4/00-palazzo.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-5" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-5/00-fasad-doma.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-6" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-6/00-bl-office.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-7" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-7/00-parus.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-8" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-8/00-kalinkovichi.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-9" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-9/00-palazzo.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-10" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-10/00-gavan.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-11" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-11/00-kz-office.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-12" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-12/00-lounge-bar.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-13" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-13/00-azs.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-14" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-14/00-rechnaya.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-15" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-15/00-maryalivo.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-16" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-16/00-staroe-selo.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
            <Link to="/gallery/stills-17" className={stylesGallery.gallery_grid_link}>
              <StaticImage src="../../images/projects/stills-17/00-dom-v-peskah.jpg" alt="Rhome project" className={stylesGallery.gallery_grid_img} />
              <p className={stylesGallery.gallery_grid_text}>Comervce 3-D visualization</p>
            </Link>
          </Masonry>
        </div>
      </section>
    </Layout>
  )
}

export default GalleryPage

const breakpointColumnsObj = {
  default: 3,
  768: 1
};