// extracted by mini-css-extract-plugin
export var index_directions = "index-module--index_directions--c5ab1";
export var index_directions_item = "index-module--index_directions_item--1adf4";
export var index_directions_items = "index-module--index_directions_items--8a4cd";
export var index_directions_title = "index-module--index_directions_title--15ebd";
export var index_gallery_button = "index-module--index_gallery_button--28113";
export var index_gallery_grid = "index-module--index_gallery_grid--fcc3a";
export var index_main_block = "index-module--index_main_block--73ac5";
export var index_main_block_img = "index-module--index_main_block_img--54daf";
export var index_main_block_img_mobile = "index-module--index_main_block_img_mobile--fb02b";
export var index_main_textblock = "index-module--index_main_textblock--a8a1d";
export var index_main_title_part2 = "index-module--index_main_title_part2--59393";