import React from 'react'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as stylesGallery from "../../components/gallery.module.css"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const stills9 = () => {
  return (
    <Layout>
      <Seo title="stills1" />
      <section>
        <div className={stylesGallery.wrapper_stills}>
          <div className={stylesGallery.stills_text}>
            <h1 className={stylesGallery.stills_title}>Comervce 3-D visualization</h1>
            <p className={stylesGallery.stills_p}>Pobediteley Avenue, 131</p>
          </div>
          <div className={stylesGallery.stills_images}>
            <StaticImage src="../../images/projects/stills-9/01-palazzo.jpg" alt="Rhome project" className={stylesGallery.stills_img} />
            <StaticImage src="../../images/projects/stills-9/02-palazzo.jpg" alt="Rhome project" className={stylesGallery.stills_img} />
            <StaticImage src="../../images/projects/stills-9/03-palazzo.jpg" alt="Rhome project" className={stylesGallery.stills_img} />
          </div>
          <section className={stylesGallery.stills_gallery_button}>
            <Link to="/gallery" className={stylesGallery.gallery_button}><p className={stylesGallery.gallery_button_p}>See all works</p></Link>
          </section>
        </div>
      </section>
    </Layout>
  )
}

export default stills9