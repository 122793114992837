import React, { useEffect, useState } from 'react';

export const IndexAnimatedTitle =()=>{

const [state , setState] = useState(false);
    useEffect(()=>{
        if(typeof window != undefined){
            setTimeout(()=>{
                setState(true);
            },50);
        }
    }, []);

  return (
    <h1>
      <span className={"index_main_title_part1" + (state? " active": "")}>Commercial</span>
      <span className={"index_main_title_part2" + (state? " active": "")}>3-D visualization</span>
    </h1>
  )   
}