// extracted by mini-css-extract-plugin
export var about_descr = "about-module--about_descr--f3473";
export var about_descr_flex = "about-module--about_descr_flex--f9cee";
export var about_descr_img = "about-module--about_descr_img--022b7";
export var about_descr_main_p = "about-module--about_descr_main_p--ceba4";
export var about_descr_p = "about-module--about_descr_p--fcdef";
export var about_main_block = "about-module--about_main_block--cc184";
export var about_main_block_img = "about-module--about_main_block_img--dcd9f";
export var about_main_block_img_mobile = "about-module--about_main_block_img_mobile--64535";
export var about_main_title = "about-module--about_main_title--2242c";
export var about_owners = "about-module--about_owners--53251";
export var about_owners_grid = "about-module--about_owners_grid--65a91";
export var about_owners_img = "about-module--about_owners_img--7ae8b";
export var about_owners_p = "about-module--about_owners_p--9cb4e";
export var about_owners_title = "about-module--about_owners_title--817ac";